@import "../../../styles/variables";
@import "../../../styles/mixins";

.footer_body_outer {
    height: 100%;
    width: 100%;
    display: block;
    .footer_body_inner {
        position: relative;
        display: block;
        .footer_info_body_outer {
            height: calc(100% - 4rem);
            padding-bottom: 0.7rem;
            .footer_info_body_inner {
                @include displayFlex(flex-start, flex-start);
                .info_icon_part {
                    width: 2.5rem;
                    .info_icon {
                        @include displayFlex(flex-start, center);
                        @include fontStyle(auto, 2.25rem, 600);
                    }
                }
                .info_text_part {
                    width: calc(100% - 2.5rem);
                    p {
                        @include fontStyle($black2, 1rem, 400);
                    }
                }
            }
        }
    }
    .footer_bottom_button {
        height: 4rem;
        button {
            height: 100%;
            border-radius: 3rem;
            box-shadow: none;
            @include fontStyle($white, 1.5rem, 600);
            text-transform: none;
        }
    }
}
