@import "../../styles/variables";
@import "../../styles/mixins";

.damage_capture_body_outer {
    .damage_capture_body_inner {
        &:not(:last-child) {
            margin-bottom: 2rem;
        }
        p {
            @include fontStyle($black, 1.2rem, 500);
        }
        .damage_group_body_outer {
            margin-top: 0.5rem;
            // @include displayFlex(flex-start, flex-start);
            // gap: 2rem;
            // flex-wrap: wrap;
            .image_uploader_wrapper {
                &:not(:last-child) {
                    margin-bottom: 2rem;
                }
            }
            .minor_chip_damage_section {
                // width: calc(100% - 2rem);
                width: 100%;
                label {
                    padding-left: 0.75rem;
                    cursor: pointer;
                    @media (max-width: 767px) {
                        font-size: 1.2rem;
                    }
                    @media (max-width: 575px) {
                        font-size: 1.5rem;
                    }
                }
                input {
                    margin: 0;
                    padding: 0;
                }
                .minor_chip_damage_section_inner {
                    padding-top: 1rem;
                    &:not(:last-child) {
                        margin-bottom: 1.5rem;
                    }
                    .minor_chip_damage_section_area_header {
                        padding-bottom: 1.5rem;
                        margin: 0;
                        @include fontStyle($black, 1.2rem, 500);
                    }
                    position: relative;
                    .delete_minor_outer {
                        position: absolute;
                        top: 5rem;
                        right: 1.25rem;
                        .minor_remove_icon {
                            font-size: 2.8rem;
                        }
                    }
                }
                .add_more_minor_outer {
                    button {
                        font-size: 1.4rem;
                        font-weight: 500;
                        &:not(:disabled) {
                            color: $black2;
                            border-color: $black2;
                        }
                        text-transform: capitalize;
                    }
                }
            }
        }
    }
}
