@import "../../styles/variables";
@import "../../styles/mixins";

.glass_body_outer {
    .glass_body_inner {
        @include displayFlex(space-between, flex-end);
        gap: 0.5rem;
        flex-wrap: wrap;
        .glass_data_body_outer {
            width: calc(50% - 0.25rem);
            &:first-child,
            &:last-child {
                width: 100%;
            }
            cursor: pointer;
            position: relative;
            height: 10rem;
            border-radius: 0.5rem;
            cursor: pointer;
            input {
                margin: 0.75rem 0 0 0.75rem;
                position: relative;
                z-index: 9;
            }
            label {
                width: 100%;
                position: absolute;
                height: 10rem;
                border-radius: 0.5rem;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                z-index: 1;
                cursor: pointer;
                .glass_data_body_inner {
                    background-color: $grey3;
                    height: 10rem;
                    border-radius: 0.5rem;
                    width: 100%;
                    z-index: -1;
                    img {
                        width: 100%;
                        height: 10rem;
                        border-radius: 0.5rem;
                        object-fit: contain;
                        z-index: -1;
                    }
                }
            }
        }
    }
}
