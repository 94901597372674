@import "./styles/variables";
@import "./styles/mixins";

.m-0 {
    margin: 0;
}
.custom_error {
    margin: 0 0 0.25rem 0;
    color: $error;
}
.app_loading {
    pointer-events: none !important;
}
.system_related_error {
    width: 100%;
    height: 100vh;
    @include displayFlex(center, center);
    .system_related_error_inner {
        max-width: 70rem;
        margin: 0 auto;
        padding: 1rem;
        img {
            width: 18rem;
            object-fit: contain;
            margin: 0 auto;
            display: block;
        }
        .text_part {
            p {
                @extend .m-0;
                text-align: center;
            }
            .head_text {
                padding: 3rem 0;
                @include fontStyle($orange, 2.5rem, 600);
            }
            .sub_text {
                @include fontStyle($black2, 2rem, 500);
            }
        }
    }
}

.no_internet_error {
    .system_related_error_inner {
        .text_part {
            .head_text {
                color: $black2;
            }
        }
    }
}
