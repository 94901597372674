$theme1Primary: #305dd2;
$theme2Primary: #9c27b0;
$theme3Primary: #ff9800;
$secondary: #f50057;
$background: #fafafa;
$error: #d32f2f;

$white: #fff;
$black: #272522;
$black2: #000000cc;
$black3: #070707cc;
$grey: #cecacb;
$grey2: #727272;
$grey3: #d8d9d8;
$orange: #f05542;

:export {
  theme1Primary: $theme1Primary;
  theme2Primary: $theme2Primary;
  theme3Primary: $theme3Primary;
  secondary: $secondary;
  background: $background;
  error: $error;
}
