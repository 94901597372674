@import "./variables";
@import "./mixins";

.status_container_outer {
    height: 100%;
    @include displayFlex(center, center);
    .status_container_inner {
        .img_part {
            height: 27vh;
            img {
                height: 100%;
                object-fit: contain;
                margin: 0 auto;
                display: block;
            }
        }
        .text_part {
            text-align: center;
            .main_text {
                padding: 3rem 0 2rem 0;
                p {
                    @include fontStyle($black, 1.4rem, 700);
                }
            }
            .sub_text {
                p {
                    @include fontStyle($black, 1.2rem, 400);
                }
            }
        }
    }
}
