@import "../../styles/variables";
@import "../../styles/mixins";

.main_container_outer {
    width: 575px;
    margin: 0 auto;
    height: 100vh;
    @media (max-width: 991px) {
        height: calc(100vh - 7rem);
    }
    position: relative;
    // background-color: $white;
    @media (max-width: 575px) {
        width: 100%;
    }
    .main_container_inner {
        padding: 0 1.5rem 1rem 1.5rem;
        height: 100%;
        .main_container_header {
            height: 4rem;
            @include displayFlex(flex-start, center);
        }
        .main_container_footer {
            height: 6rem;
            @include displayFlex(center, flex-end);
            .main_container_footer_outer {
                width: 100%;
            }
        }
        .main_container_body_outer {
            height: calc(100% - 10.5rem);
            padding: 1rem 0 0.5rem 0;
            .main_container_body_inner {
                height: 100%;
                overflow-y: auto;
            }
        }
    }
}
