@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;700;900&display=swap");

* {
  font-family: "Poppins", sans-serif;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Poppins", sans-serif;
}

html {
  @media (max-width: 1199px) {
    font-size: 90%;
  }
  @media (max-width: 991px) {
    font-size: 85%;
  }
  @media (max-width: 767px) {
    font-size: 80%;
  }
  @media (max-width: 575px) {
    font-size: 75%;
  }
  @media (max-width: 450px) {
    font-size: 62%;
  }
}
