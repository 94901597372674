@import "../../styles/variables";
@import "../../styles/mixins";

.mandatory_body_outer {
    width: 100%;
    .mandatory_body_inner {
        width: 100%;
        margin-top: 0.5rem;
        // @include displayFlex(center, flex-start);
        // gap: 2rem;
        // flex-wrap: wrap;
        .image_uploader_wrapper {
            &:not(:last-child) {
                margin-bottom: 2rem;
            }
        }
    }
}
