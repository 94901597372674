@import "../../../styles/variables";
@import "../../../styles/mixins";

.imageUploaderOuter {
    // width: 14rem;
    width: 100%;
    .imageUploaderInner {
        width: 100%;
        overflow: hidden;
        position: relative;
        border-radius: 1rem;
        .overlay_part {
            height: 15rem;
            position: relative;
            @include displayFlex(center, center);
            padding: 1.5rem 1rem;
            background-color: $grey;
            .overlay_part_inner {
                overflow: hidden;
                height: 100%;
                width: 100%;
                @include displayFlex(center, flex-start);
                img {
                    // width: 15rem;
                    height: 13rem;
                    object-fit: contain;
                }
            }
        }
        .capture_part {
            height: 4rem;
            background-color: $black3;
            position: relative;
            input {
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0;
                height: 4rem;
                width: 100%;
                z-index: 9;
                cursor: pointer;
            }
            @include displayFlex(center, center);
            .camera_icon {
                @include displayFlex(center, center);
                @include fontStyle($white, 2.5rem, 400);
                margin-right: 0.75rem;
            }
            p {
                @include fontStyle($white, 1.5rem, 400);
            }
        }
        .image_previewer {
            height: 19rem;
            width: 100%;
            position: relative;
            @include displayFlex(center, center);
            background-color: $grey;
            padding: 0.5rem 0;
            img {
                height: 100%;
                object-fit: contain;
            }
            .close_icon {
                position: absolute;
                font-size: 2rem;
                // top: 0.5rem;
                // right: 0.5rem;
                top: 1rem;
                right: 1rem;
                cursor: pointer;
            }
        }
    }
    .part_label {
        p {
            @include fontStyle($grey2, 1.4rem, 400);
            text-align: center;
            padding-top: 0.7rem;
            text-transform: lowercase;
            &::first-letter {
                text-transform: uppercase;
            }
        }
    }
}

.imageUploaderOuterMinor {
    width: 100% !important;
    .imageUploaderInner {
        border-radius: 0;
    }
    .minor_chip_info_section {
        margin-top: 1rem;
        @include displayFlex(flex-start, center);
        .info_icon_part {
            width: 2.25rem;
            .info_icon {
                margin: 0;
                padding: 0;
                @include displayFlex(flex-start, flex-start);
                @include fontStyle(auto, 2.25rem, 600);
            }
        }
        .info_text_part {
            width: calc(100% - 2.5rem);
            padding-left: 0.75rem;
            p {
                @include fontStyle($black2, 1.2rem, 400);
            }
        }
    }
    .overlay_part {
        padding: 0 !important;
        .overlay_part_inner {
            height: 100% !important;
            img {
                object-fit: cover !important;
                width: 100% !important;
                height: 100% !important;
            }
        }
    }
}

.imageUploaderOuterVIN {
    // width: 100% !important;
    // .imageUploaderInner {
    //     width: 14rem;
    //     margin: 0 auto;
    // }
    // .vin_input_outer {
    //     width: 22rem;
    //     margin: 1rem auto;
    //     input {
    //         padding: 0.5rem 0.5rem;
    //         @include fontStyle($black2, 1.5rem, 400);
    //     }
    // }
}
