@import "../../../styles/variables";
@import "../../../styles/mixins";

.header_body {
    height: 100%;
    width: 100%;
    .header_top_body {
        height: calc(100% - 0.3rem);
        @include displayFlex(flex-start, center);
        .arrow_part {
            width: 3rem;
            .arrow_back_icon {
                @include displayFlex(flex-start, center);
                @include fontStyle($black, 2rem, 600);
                cursor: pointer;
            }
        }
        .text_part {
            width: calc(100% - 3rem);
            @include fontStyle($black, 1.4rem, 600);
        }
    }
    .header_bottom_body {
        height: 0.3rem;
        background: linear-gradient(90deg, $theme1Primary 0%, rgba(0, 90, 136, 0) 100%);
    }
}
